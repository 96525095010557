import PageLayout from '../components/PageLayout';
import SectionTitle from '../components/SectionTitle';

import landewyck from '../assets/clients/landewyck-logo.svg';
import jaguar from '../assets/clients/Jaguar.svg';
import domashny from '../assets/clients/D_domashny.png';
// import sberbank from '../assets/clients/Sber_bank.png';
// import vtb from '../assets/clients/VTB.png';
// import rosneft from '../assets/clients/Rosneft.png';
import ufc from '../assets/clients/UFC.png';
// import gpb from '../assets/clients/Gazprombank.png';

import './About.scss';

const AboutPage = () => {
  return (
    <PageLayout>
      <div className="page-layout__content">
        <SectionTitle sphere="/spheres/about-main.png">About</SectionTitle>
        <p className="about--description">
          At <b>8points</b> we involve our clients to be part of the interesting journey to uncover the visual language
          of <b>their business</b>
        </p>

        <h3 className="about--heading">Our clients</h3>
        <p className="about--clients">
          <a href="https://landewyck.com/" target="_blank" rel="noreferrer">
            <img src={landewyck} alt="Landewyck logo" title="Landewyck" />
          </a>
          <a href="https://www.jaguar.com/" target="_blank" rel="noreferrer">
            <img src={jaguar} alt="Jaguar logo" title="Jaguar" />
          </a>
          <a href="https://domashniy.ru/" target="_blank" rel="noreferrer">
            <img src={domashny} alt="Domashny logo" title="Domashny TV Channel" />
          </a>
          <a href="https://www.ufc.com/" target="_blank" rel="noreferrer">
            <img src={ufc} alt="UFC logo" title="UFC" />
          </a>
          {/* <img src={sberbank} alt="SberBank logo" title="Sber Bank" />
          <img src={vtb} alt="VTB logo" title="Bank VTB" />
          <img src={rosneft} alt="Rosneft logo" title="Rosneft" /> */}
          {/* <img src={gpb} alt="GazPromBank logo" title="Gazprom Bank" /> */}
        </p>

        <h3 className="about--heading">Core Team</h3>
        <div className="about--team">
          <div className="about--member">
            <img className="about--member-photo" src="/about/Kirill.jpg" alt="Kirill Mitsurov" />
            <h4 className="about--member-name">Kirill</h4>
            <p className="about--member-position">Founder</p>
          </div>
          <div className="about--member">
            <img className="about--member-photo" src="/about/Anton.jpg" alt="Anton Bolshakov" />
            <h4 className="about--member-name">Anton</h4>
            <p className="about--member-position">Co-founder</p>
          </div>
          <div className="about--member">
            <img className="about--member-photo" src="/about/Aleksey.jpg" alt="Aleksey Ananyev" />
            <h4 className="about--member-name">Aleksey</h4>
            <p className="about--member-position">Art Director</p>
          </div>
          <div className="about--member">
            <img className="about--member-photo" src="/about/Milena.jpg" alt="Milena Usanova" />
            <h4 className="about--member-name">Milena</h4>
            <p className="about--member-position">Animator &amp; Video editor</p>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default AboutPage;
